<template>
  <div class="vuse-content-wrapper audit-tree">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <!-- <help class="help--breadcrumb" :helpId="32" /> -->
    </v-row>
    <v-card>
      <v-toolbar class="py-1" color="primary" dark height="auto">
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="d-flex align-center"
            ><v-row no-gutters>
              <v-col cols="12" xs="8" sm="12">
                <v-toolbar-title class="text-h6">
                  {{
                    assignedEvaluation.evaluation && assignedEvaluation.evaluation.name
                      ? assignedEvaluation.evaluation.name + ' #' + assignedEvaluation.id
                      : ''
                  }}
                </v-toolbar-title>
                <v-row no-gutters v-if="assignedEvaluation.status !== 0" align="center">
                  <span class="caption mr-2">Progreso general: </span>
                  <v-col>
                    <v-progress-linear
                      dark
                      rounded
                      color="teal"
                      height="15"
                      style="max-width: 200px"
                      :value="
                        assignedEvaluation.progress ? assignedEvaluation.progress : 0
                      "
                      ><strong
                        >{{
                          assignedEvaluation.progress
                            ? Math.ceil(assignedEvaluation.progress)
                            : 0
                        }}
                        %</strong
                      ></v-progress-linear
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn
                  class="mr-2"
                  x-small
                  rounded
                  dark
                  :color="assignedEvaluation.statusColor"
                  >{{ assignedEvaluation.statusName }}</v-btn
                >
                <v-btn
                  class="mr-2"
                  rounded
                  x-small
                  color="teal"
                  v-if="assignedEvaluation.status === 2"
                  @click="$modal.show('crudModal')"
                  >Marcar como finalizada</v-btn
                ><v-btn
                  rounded
                  x-small
                  color="teal"
                  v-if="loadedResults"
                  @click="$modal.show('resultsModal')"
                  >Ver Resultados</v-btn
                >
              </v-col>
            </v-row></v-col
          >
          <v-col cols="12" sm="6"
            ><span class="caption" style="float: right" v-if="assignedEvaluation.user">
              Evaluador:
              {{
                assignedEvaluation.user.name + ' ' + assignedEvaluation.user.lastname
              }} </span
            ><br /><span
              class="caption"
              style="float: right"
              v-if="assignedEvaluation.company"
              >Empresa: {{ assignedEvaluation.company.name }}</span
            ><br /><span
              class="caption"
              style="float: right"
              v-if="assignedEvaluation.date"
            >
              {{ toLocaleDateString(assignedEvaluation.date) }}</span
            ></v-col
          >
        </v-row>
      </v-toolbar>
      <v-row class="pa-0 m-0" justify="space-between" no-gutters>
        <v-col cols="12" sm="5" style="height: 100%; overflow-y: auto">
          <v-treeview
            style="height: 90vh; overflow-y: auto"
            v-if="!loading && evaluations.length > 0"
            :active.sync="active"
            :open.sync="open"
            :items="evaluations"
            item-children="main_requirements"
            item-text="name"
            dense
            :multiple-active="false"
            shaped
            hoverable
            activatable
            open-on-click
            transition
            color="primary"
            :return-object="true"
            selected-color="primary"
          >
            <template v-slot:prepend="{ item }">
              <v-tooltip :key="checkIndex" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :key="checkIndex"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    dark
                    v-if="item.evaluated"
                    ><v-icon color="primary">check</v-icon>
                  </v-btn>
                </template>
                <span>Respuesta guardada</span>
              </v-tooltip>
            </template>
            <template v-slot:label="{ item }">
              <div class="label-container" @click.prevent="getSelectedItem(item)">
                <p class="label">
                  {{ item.name }}
                </p>
              </div>
            </template>
          </v-treeview>
          <div v-else-if="loading">
            <v-skeleton-loader v-for="index in 8" :key="index" type="list-item-avatar" />
          </div>
          <v-row
            class="mx-0 my-4"
            justify="center"
            v-else-if="!loading && evaluations.length === 0"
          >
            <h3>No hay secciones para evaluar</h3>
          </v-row>
        </v-col>

        <v-col cols="12" sm="7" class="d-flex text-center" :key="cardIndex">
          <v-divider vertical />
          <v-card
            flat
            v-if="selectedEvaluation.id"
            :key="selectedEvaluation.id"
            class="text-h4 font-weight-light new-glow"
          >
            <v-card-text class="mx-auto new-glow py-5" flat>
              <v-row class="mx-0 grey--text" align="baseline">
                <v-col cols="12">
                  <h2 class="mx-auto">Evaluación de {{ selectedEvaluation.name }}</h2>
                </v-col>
                <v-col cols="12">
                  <v-row style="margin: 0px" class="my-auto">
                    <label class="mr-2">Pertenece a: </label>
                    <multiselect
                      style="width: 80%"
                      track-by="id"
                      label="name"
                      placeholder="Seleccionar"
                      v-model="selectedEvaluation.father"
                      :options="selectedEvaluation.options"
                      :disabled="true"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="false"
                    />
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Nombre"
                    v-model="selectedEvaluation.name"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Duración"
                    v-model="selectedEvaluation.duration"
                    suffix="día(s)"
                    step="1"
                    min="1"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Peso ponderado"
                    v-model="selectedEvaluation.weighing"
                    suffix="%"
                    step="1"
                    min="1"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Resultado mínimo requerido"
                    v-model="selectedEvaluation.score"
                    type="number"
                    step="1"
                    min="1"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-else-if="selectedRequirement.id"
            :key="selectedRequirement.id"
            class="text-h4 font-weight-light new-glow"
            style="width: 100%"
            flat
          >
            <v-card-text v-if="selectedRequirement.evaluation">
              <h1 style="width: fit-content" class="mb-8">
                Sección: {{ selectedRequirement.evaluation.name }}
              </h1>
              <v-row justify="center" align="center" no-gutters class="my-5">
                <h2>{{ selectedRequirement.description }}</h2>
              </v-row>
              <p v-if="selectedRequirement.observation">
                Observación del parámetro a evaluar:
                {{ selectedRequirement.observation }}
              </p>
              <p>
                Tipo de pregunta: {{ selectedRequirement.type | toRequirementTypeText }}
              </p>

              <v-radio-group v-if="selectedRequirement.type == 1" v-model="value">
                <v-radio :value="1">
                  <template v-slot:label>
                    <div>SI</div>
                  </template>
                </v-radio>
                <v-radio :value="0">
                  <template v-slot:label>
                    <div>NO</div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-card
                v-else-if="
                  selectedRequirement.type === 2 || selectedRequirement.type === 3
                "
                class="mx-auto"
                outlined
                color="transparent"
              >
                <h1>{{ value }} {{ value === 1 ? ' punto' : ' puntos' }}</h1>
                <v-slider
                  v-model="value"
                  track-color="grey"
                  always-dirty
                  min="1"
                  :readonly="assignedEvaluation.status === 3"
                  :max="maxRequirement"
                >
                  <template v-slot:prepend>
                    <v-icon
                      color="primary"
                      v-if="assignedEvaluation.status !== 3"
                      @click="value--"
                    >
                      mdi-minus
                    </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon
                      color="primary"
                      v-if="assignedEvaluation.status !== 3"
                      @click="value++"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                </v-slider>
              </v-card>
              <v-card
                :key="index2"
                outlined
                color="transparent"
                v-if="selectedRequirement.type == 4"
                class="mx-auto"
              >
                <v-radio-group :mandatory="true" v-model="selectedOption">
                  <v-radio
                    :disabled="
                      assignedEvaluation.status === 3 &&
                      option.id !== selectedRequirement.evaluatedData.requirement_id2
                    "
                    :value="option"
                    v-for="option in selectedRequirement.childrens"
                    :key="option.id"
                  >
                    <template v-slot:label>
                      <div>
                        {{ option.description + ': ' + option.required_value }}
                        {{ option.required_value === 1 ? ' punto' : ' puntos' }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card>

              <v-row
                no-gutters
                justify="center"
                v-if="selectedRequirement.guidelines.length > 0"
              >
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="blue darkend-4" text
                      >Criterios de evaluación
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5"> Criterios de evaluación </v-card-title>
                    <v-card-text class="px-2"
                      ><v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Descripción</th>
                              <th class="text-center" width="50px">Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="guideline in selectedRequirement.guidelines"
                              :key="guideline.id"
                            >
                              <td>{{ guideline.description }}</td>
                              <td class="text-center">{{ guideline.value }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="green darken-1" text @click="dialog = false">
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>

              <v-row class="text-left m-0 align-items-center">
                <v-col cols="12" align="center" class="d-flex" style="align-items: center"
                  ><v-textarea
                    :rules="[rules.max]"
                    counter="400"
                    v-model="observation"
                    :clearable="assignedEvaluation.status !== 3"
                    rows="6"
                    :readonly="assignedEvaluation.status === 3"
                    clear-icon="mdi-close-circle"
                    label="Observaciones del evaluador"
                /></v-col>
                <v-col
                  cols="12"
                  align="center"
                  class="d-flex"
                  v-if="assignedEvaluation.status !== 3"
                  ><v-file-input
                    v-model="file"
                    :rules="[rules.fileSize]"
                    accept="image/png, image/jpeg, image/jpg"
                    placeholder="Subir imagen"
                    prepend-icon="mdi-camera"
                    label="Evidencia"
                    show-size
                  />
                </v-col>
                <v-carousel
                  cycle
                  height="300"
                  hide-delimiter-background
                  show-arrows-on-hover
                  v-if="
                    selectedRequirement.evaluated &&
                    selectedRequirement.evaluatedData &&
                    selectedRequirement.evaluatedData.image
                  "
                >
                  <v-carousel-item v-for="(image, i) in carouselImages" :key="i">
                    <v-img
                      :src="image.src"
                      contain
                      aspect-ratio="1"
                      alt=""
                      class="carousel__image"
                      height="300"
                      @click="showImg(index)"
                    >
                      <!-- <v-btn
                      small
                      class="btn--fullscreen"
                      depressed

                    >
                      <v-icon> mdi-fullscreen </v-icon>
                    </v-btn> -->
                    </v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-row>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    dark
                    v-if="assignedEvaluation.status !== 3"
                    @click.prevent="handleSave()"
                    >Guardar
                  </v-btn>
                </template>
                <span>Guardar datos de la pregunta</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
          <v-card flat v-else class="text-h4 font-weight-light new-glow">
            <v-card-text
              class="mx-auto new-glow py-5"
              flat
              v-if="assignedEvaluation.evaluation"
            >
              <v-row class="mx-0 grey--text" align="baseline">
                <v-col cols="12">
                  <h2 class="mx-auto">Detalles de la Evaluación</h2>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Nombre"
                    v-model="assignedEvaluation.evaluation.name"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Duración"
                    v-model="assignedEvaluation.evaluation.duration"
                    suffix="día(s)"
                    step="1"
                    min="1"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Peso ponderado"
                    v-model="assignedEvaluation.evaluation.weighing"
                    suffix="%"
                    step="1"
                    min="1"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    readonly
                    label="Resultado mínimo requerido"
                    v-model="assignedEvaluation.evaluation.score"
                    type="number"
                    step="1"
                    min="1"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    readonly
                    :label="'Observación de la evalución'"
                    v-model="assignedEvaluation.observation"
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <modal
      name="resultsModal"
      :height="'95%'"
      :width="$vuetify.breakpoint.smAndDown ? '90%' : '70%'"
    >
      <assigned-evaluation-report
        :assignedEvaluation="assignedEvaluation"
        :sections="evaluations"
      />

      <v-row align="center" justify="space-between" class="my-4" style="height: 100%">
        <v-btn
          small
          style="margin: auto; margin-top: 10px"
          color="primary"
          @click="$refs.html2Pdf.generatePdf()"
        >
          Descargar
        </v-btn>
        <v-btn
          small
          style="margin: auto; margin-top: 10px"
          color="primary"
          @click="$modal.hide('resultsModal')"
        >
          Cerrar
        </v-btn>
      </v-row>
    </modal>
    <modal name="crudModal">
      <v-card color="transparent" style="padding: 20px">
        <h3 class="text-center mb-5">Confirmar finalización de la evaluación</h3>
        <v-form v-model="valid">
          <v-row class="mx-0" justify="center">
            <v-col cols="12">
              <v-text-field
                :rules="[rules.required]"
                label="Fecha efectiva de finalización"
                v-model="effectiveEndDate"
                type="date"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                :rules="[rules.required, rules.max]"
                :counter="400"
                v-model="conclusion"
                label="Conclusión del evaluador"
                rows="2"
                :auto-grow="true"
              />
            </v-col>
          </v-row>
          <div class="row">
            <v-btn
              style="margin: auto; margin-top: 10px"
              color="success"
              :disabled="!valid"
              @click="handleRevised()"
            >
              Confirmar
            </v-btn>
            <v-btn
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="$modal.hide('crudModal')"
            >
              Cerrar
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </modal>
    <VueHtml2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1500"
      :manual-pagination="false"
      pdf-format="letter"
      pdf-content-width="100%"
      :pdf-quality="2"
      :filename="
        assignedEvaluation.evaluation
          ? `${assignedEvaluation.evaluation.name} #${assignedEvaluation.id} - ${
              assignedEvaluation.company.name
            } - ${assignedEvaluation.date.split(' ')[0]}`
          : 'Resumen de Evaluación Finalizada'
      "
      @beforeDownload="beforeDownload($event)"
    >
      <section class="v-application" slot="pdf-content">
        <assigned-evaluation-report
          :assignedEvaluation="assignedEvaluation"
          :sections="evaluations"
        />
        <!-- <div class="v-application">
          <v-main>
            <v-container>
               </v-container
          ></v-main>
        </div> -->
      </section>
    </VueHtml2pdf>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="carouselImages"
      :index="lightboxIndex"
      @hide="handleHideLightbox"
    />
  </div>
</template>
<script>
  import colors from 'vuetify/es5/util/colors'
  import VueHtml2pdf from 'vue-html2pdf'
  import EvaluationStatus from '@/data/SupervisionControl/evaluationStatus'
  import AssignedEvaluationReport from '@/components/SupervisionControl/AssignedEvaluationReport'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'AuditCompany',
    components: {
      VueHtml2pdf,
      AssignedEvaluationReport,
    },
    data() {
      return {
        loadedResults: false,
        config: {},
        tab: 0,
        valid: true,
        selectedItem: 0,
        idObject: 0,
        action: 0,
        bpm: 0,
        interval: null,
        isPlaying: false,
        requirements: [],
        evaluations: [],
        active: [],
        lightboxIndex: 100,
        visible: false,
        open: [],
        avatar: null,
        dialog: false,
        navigationElements: [
          {
            text: 'Control y Supervisión',
            disabled: false,
            query: null,
            params: null,
            name: 'business/supervision-control/Dashboard',
          },
          {
            text: 'Evaluaciones Asignadas',
            disabled: false,
            query: null,
            params: null,
            name: 'business/supervision-control/GlobalAssignedEvaluations',
          },
          {
            text: 'Evaluación Asignada',
            disabled: true,
          },
        ],
        headers: [
          {
            text: 'Sección Evaluada',
            align: 'start',
            sortable: false,
            value: 'description',
          },
          {
            text: 'Puntuación Obtenida',
            sortable: false,
            value: 'required_value',
            align: 'center',
          },
          {
            text: 'Puntuación Objetivo',
            sortable: false,
            value: 'required_value',
            align: 'center',
          },
          {
            text: 'Peso Ponderado',
            sortable: false,
            value: 'required_value',
            align: 'center',
          },
          { text: 'Resultado', value: 'result', align: 'center' },
        ],

        assignedEvaluation: {},
        selectedEvaluation: {},
        selectedRequirement: {},
        carouselImages: [],
        selectedOption: null,
        rules: {
          required: (v) => !!v || 'Requerido.',
          max: (v) =>
            (v ? v.length <= 400 : true) || 'Debe poser menos de 400 caracteres',
          fileSize: (v) =>
            !v || v.size < 5120000 || 'El peso del archivo debe ser inferior a 5MB',
        },
        value: 1,
        observation: '',
        file: null,
        checkIndex: 0,
        index: 1,
        index2: 0,
        cardIndex: 0,
        loading: true,
        chartData: {
          labels: [],
          datasets: [
            {
              gradientFill: [
                [colors.blue.darken2],
                [colors.green.lighten1],
                [colors.teal.lighten1],
                [colors.grey.lighten3],
                [colors.red.lighten1],
              ],
              data: [5, 2.1, 7.5, 11],
            },
          ],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: 'bottom',
            width: '30%',
          },

          plugins: {
            datalabels: {
              color: 'white',
              textAlign: 'center',
              display: true,
              title: {
                display: true,
                text: 'Resultado por sección de la evaluación',
              },
              font: {
                weight: 'bold',
                size: 16,
              },
              tooltips: {
                enabled: false,
                fixed: true,
              },
            },
          },
        },
        sections: 0,
        obtainedResut: 0,
        effectiveEndDate: new Date().toISOString().split('T')[0],
        conclusion: '',
      }
    },
    watch: {
      assignedEvaluation: {
        handler(newValue) {
          this.getStatusData(newValue)
        },
        deep: true,
      },
    },
    computed: {
      userId() {
        if (this.$session.get('userId')) {
          return decryptData(this.$session.get('userId'))
        } else {
          return false
        }
      },
      name() {
        if (this.$session.get('tokenSession')) {
          return decryptData(this.$session.get('userName'))
        } else {
          return false
        }
      },
      lastname() {
        if (this.$session.get('tokenSession')) {
          return decryptData(this.$session.get('userLastname'))
        } else {
          return false
        }
      },
      maxRequirement() {
        if (!this.selectedRequirement || !this.selectedRequirement.type) return ''
        switch (this.selectedRequirement.type) {
          case 2:
            return 5
          default:
            return 10
        }
      },
    },
    filters: {
      toRequirementTypeText(type) {
        if (!type) return ''
        switch (type) {
          case 1:
            return 'Si / No'
          case 2:
            return 'Valor 1 a 5'
          case 3:
            return 'Valor 1 a 10'
          case 4:
            return 'Selección simple'
          default:
            return ''
        }
      },
      toStatusText(status) {
        switch (status) {
          case 0:
            return 'Asignada'
          case 1:
            return 'En curso'
          case 2:
            return 'En revisión'
          case 3:
            return 'Completada'
          case null:
            return ''
          default:
            return ''
        }
      },
    },
    created() {
      if (Number.isInteger(parseInt(this.$route.params.assignedEvaluationId))) {
        this.getAssignedEvaluation()
      } else {
        this.$router.push({ name: 'business/supervision-control/Dashboard' })
      }
    },

    methods: {
      toLocaleDateString: toLocaleDateString,
      showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHideLightbox() {
        this.visible = false
      },
      getAssignedEvaluation() {
        this.$axios
          .get('assigned-evaluations/' + this.$route.params.assignedEvaluationId)
          .then((response) => {
            this.assignedEvaluation = response.data
            this.navigationElements[2].text =
              this.assignedEvaluation.evaluation.name + ' #' + this.assignedEvaluation.id
            this.getStatusData(this.assignedEvaluation)
            this.getEvaluations()
          })
      },
      handleRevised() {
        this.$swal({
          title: '¿Estás seguro?',
          text: 'No podrás modificar la información guardada a partir de este punto',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, guardar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$modal.hide('crudModal')
            this.conclusion = ''
            this.effectiveEndDate = new Date().toISOString().split('T')[0]
            this.changeAssignationStatus(3)
          }
        })
      },
      getEvaluations() {
        this.loading = true
        this.$axios
          .get(
            'evaluations?limit=60&evaluation_id=' +
              this.assignedEvaluation.evaluation_id +
              '&assigned_evaluation_id=' +
              this.assignedEvaluation.id
          )
          .then((response) => {
            this.evaluations = response.data
            if (this.assignedEvaluation.status === 3) {
              this.sections = this.evaluations.length
              const labels = []
              const data = []
              this.evaluations.forEach((evaluation) => {
                labels.push(evaluation.name)
                data.push(evaluation.result.toFixed(2))
              })
              this.obtainedResut = data.reduce(
                (partialSum, a) => partialSum + parseInt(a),
                0
              )
              const diff =
                this.assignedEvaluation.evaluation.total_score.toFixed(2) -
                this.obtainedResut
              labels.push('No alcanzado')
              data.push(diff)
              this.chartData.labels = labels
              this.chartData.datasets[0].data = data
              this.loadedResults = true
              this.$modal.show('resultsModal')
            }
            this.loading = false
          })
      },
      getStatusData(evaluation) {
        evaluation.statusName = EvaluationStatus.find(
          (status) =>
            status.id === evaluation.status &&
            status.passed === evaluation.passed &&
            status.failed === evaluation.failed
        ).name
        evaluation.statusColor = EvaluationStatus.find(
          (status) =>
            status.id === evaluation.status &&
            status.passed === evaluation.passed &&
            status.failed === evaluation.failed
        ).color
      },
      changeAssignationStatus(status) {
        const data = {
          status: status,
        }
        if (status === 3) {
          data.effective_end_date = this.effectiveEndDate
          data.conclusion = this.conclusion
        }
        this.$axios
          .patch('assigned-evaluations/' + this.$route.params.assignedEvaluationId, data)
          .then((response) => {
            this.assignedEvaluation = response.data.assignedEvaluation
          })
      },
      getSelectedItem(selected) {
        if (!selected.main_requirements) {
          this.selectedRequirement = selected
          this.setEvaluatedData()
          this.selectedEvaluation = {}
          this.getCarouselImages([this.selectedRequirement.evaluatedData.image])
        } else {
          this.selectedEvaluation = selected
          this.selectedEvaluation.options = [selected.father]
          this.selectedRequirement = {}
        }
        this.cardIndex++
      },
      getCarouselImages(imageArray) {
        imageArray.forEach((image) => {
          this.carouselImages.push({
            src: image.route,
          })
        })
      },
      handleSave() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }
        const formData = new FormData()
        formData.append('requirement_id', this.selectedRequirement.id)
        formData.append('assigned_evaluation_id', this.$route.params.assignedEvaluationId)
        formData.append('user_id', this.userId)
        formData.append('observation', this.observation)
        if (this.selectedRequirement.type === 4) {
          formData.append('value', this.selectedOption.required_value)
          formData.append('requirement_id2', this.selectedOption.id)
        } else {
          formData.append('value', this.value)
        }
        formData.append('latitude', 'latitude')
        formData.append('longitude', 'longitude')
        if (this.file && this.file !== '' && this.file !== undefined) {
          formData.append('file', this.file)
          formData.append('fileType', 'image')
        }
        if (
          this.selectedRequirement.evaluatedData &&
          this.selectedRequirement.evaluatedData.id
        ) {
          formData.append('_method', 'patch')
          this.$axios
            .post(
              'applied-evaluations/' + this.selectedRequirement.evaluatedData.id,
              formData,
              this.config
            )
            .then((response) => {
              this.assignedEvaluation =
                response.data.appliedEvaluation.assigned_evaluation
              this.handleAppliedEvaluation(response.data.appliedEvaluation, 'edit')
              this.handleNextRequirement()
            })
        } else {
          this.$axios
            .post('applied-evaluations', formData, this.config)
            .then((response) => {
              this.assignedEvaluation =
                response.data.appliedEvaluation.assigned_evaluation
              this.handleAppliedEvaluation(response.data.appliedEvaluation, 'create')
              this.checkIndex++
              this.handleNextRequirement()
            })
        }
      },
      async beforeDownload({ html2pdf, options }) {
        options.pagebreak = {
          mode: ['avoid-all'],
          // before: '.data-table',
          avoid: '.avoid-pagebreak, tr, picture, img',
        }
        // options.jsPDF.unit = 'cm'
        await html2pdf().set(options)
      },
      handleAppliedEvaluation(appliedEvaluation, state) {
        if (state === 'create') {
          this.selectedRequirement.applied_evaluations.push(appliedEvaluation)
          this.selectedRequirement.evaluated = true
        } else if (state === 'edit') {
          const array = this.selectedRequirement.applied_evaluations.filter(
            (appliedEvaluation) =>
              appliedEvaluation.id !== this.selectedRequirement.evaluatedData.id
          )
          this.selectedRequirement.applied_evaluations = array
          this.selectedRequirement.applied_evaluations.push(appliedEvaluation)
        }

        this.setEvaluatedData()
      },
      setEvaluatedData() {
        this.selectedRequirement.evaluatedData = this.selectedRequirement.applied_evaluations.find(
          (evaluation) =>
            evaluation.assigned_evaluation_id ===
              parseInt(this.$route.params.assignedEvaluationId) &&
            evaluation.requirement_id === this.selectedRequirement.id
        )

        if (this.selectedRequirement.evaluatedData !== undefined) {
          this.observation = this.selectedRequirement.evaluatedData.observation
          this.value = this.selectedRequirement.evaluatedData.value
          if (this.selectedRequirement.evaluatedData.requirement2) {
            this.selectedOption = this.selectedRequirement.evaluatedData.requirement2
            this.index2++
          }
          if (
            this.selectedRequirement.childrens &&
            this.selectedRequirement.childrens.length > 0
          ) {
            this.selectedRequirement.childrens.sort((a) => {
              if (a.id !== this.selectedRequirement.evaluatedData.requirement_id2) {
                return +1
              } else {
                return -1
              }
            })
          }
        } else {
          this.observation = ''
          this.value = 1
          this.file = null
          if (
            this.selectedRequirement.childrens &&
            this.selectedRequirement.childrens.length > 0
          ) {
            this.selectedRequirement.childrens.sort((a, b) => {
              return a.required_value - b.required_value
            })
          }
        }
      },
      handleNextRequirement() {
        const activeEvaluation = this.evaluations.find(
          (evaluation) => evaluation.id === this.selectedRequirement.evaluation_id
        )
        let active = activeEvaluation.main_requirements.find(
          (requirement) => requirement.id === this.selectedRequirement.id
        )
        const activeInOpen = activeEvaluation.main_requirements.indexOf(active)
        if (activeInOpen < activeEvaluation.main_requirements.length - 1) {
          active = activeEvaluation.main_requirements[activeInOpen + 1]
          this.active = [active]
          this.open = [activeEvaluation]
          this.selectedRequirement = active
          this.setEvaluatedData()
        }
        this.index++
      },
    },
  }
</script>
<style lang="scss">
  .v-application--is-ltr .v-treeview-node__content {
    margin-left: 0px;
    max-width: 94%;
  }
  .audit-tree {
    .audit-tree.v-treeview-node__root {
      padding: 0px;
      justify-content: flex-end;
    }
    .v-treeview-node__level {
      width: 15px;
    }
    .v-subheader {
      height: auto;
    }
    .v-treeview-node__content {
      align-items: center;
      height: 40px;
    }
    // .v-toolbar__content,
    // .v-toolbar__extension {
    //   padding: 10px 10px 0px 10px;
    // }
    // .evaluationResult {
    //   font-style: oblique;
    //   font-weight: 100;
    //   font-size: 2rem;
    // }
    .v-progress-circular__info {
      display: grid;
    }
    .v-data-table tbody tr:nth-of-type(odd) {
      background-color: transparent;
    }
    .label-container {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .label {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .carousel__image {
      cursor: zoom-in;
    }
  }
</style>
