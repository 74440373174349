export default [
  { id: 0, passed: false, failed: false, name: "Asignada", color: "#00b0f0" },
  { id: 1, passed: false, failed: false, name: "En curso", color: "#808080" },
  {
    id: 2,
    passed: false,
    failed: false,
    name: "En revisión",
    color: "#feb019"
  },
  { id: 3, passed: false, failed: false, name: "Completada", color: "#003955" },
  {
    id: 3,
    passed: true,
    failed: false,
    name: "Completada: Aprobada",
    color: "#003a56"
  },
  {
    id: 3,
    passed: false,
    failed: true,
    name: "Completada: Reprobada",
    color: "#cc0000"
  }
];
