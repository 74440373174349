<template>
  <v-card
    color="white"
    class="v-application assigned-evaluation-report pdf-content"
    data-app
    flat
  >
    <v-toolbar dark color="primary" height="60" width="100%" class="py-0" tile>
      <!-- :clipped-left="isClippedLeft"
      :clipped-right="isClippedRight"
      v-if="isVisibleHeader"
      v-bind="bindStyle"
      :dense="isDense"
      :hide-on-scroll="isHideOnScroll"
      :shrink-on-scroll="isShrinkOnScroll" -->
      <v-row no-gutters align="center" justify="center" style="width: 100%">
        <img src="/static/default/logo_blanco--solo-nube.svg" class="mr-2" />
        <h3 class="d-flex align-center">
          <!-- <v-img
            src="/static/default/logo_blanco--solo-nube.svg"
            width="56"
            class="mr-2"
          /> -->
          INFORME DE RESULTADOS DE UNA EVALUACIÓN
        </h3>
      </v-row>
    </v-toolbar>
    <v-container>
      <!-- <v-card class="avoid-pagebreak" flat> -->
      <v-row class="mx-8" align="center">
        <v-col cols="12" md="6" class="d-flex flex-column align-center align-md-start">
          <div>
            <span>Evaluación realizada:</span>
            <h2 class="">
              {{
                assignedEvaluation.evaluation ? assignedEvaluation.evaluation.name : ''
              }}
              {{ '#' + assignedEvaluation.id }}
            </h2>
            <span>
              <strong> Estatus: </strong>
              <v-btn
                class="ml-2"
                rounded
                dark
                x-small
                :color="assignedEvaluation.statusColor"
                >{{ assignedEvaluation.statusName }}</v-btn
              >
            </span>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <span>
            <strong>Fecha asignada:</strong>
            {{ toLocaleDateString(assignedEvaluation.date) }} </span
          ><br />
          <span>
            <strong>Fecha de finalización:</strong>
            {{ toLocaleDateString(assignedEvaluation.effective_end_date) }} </span
          ><br />
          <span v-if="assignedEvaluation.user">
            <strong>Evaluador:</strong>
            {{
              assignedEvaluation.user.name + ' ' + assignedEvaluation.user.lastname
            }} </span
          ><br />

          <span v-if="assignedEvaluation.company">
            <strong>
              Unidad evaluada:
              {{ assignedEvaluation.company.name }}
            </strong>
          </span>
        </v-col>

        <v-card tag="section" class="disabled-textfield pa-3" width="100%" flat>
          <v-card-title class="text-body-1 text pb-0"
            >Observación de la evaluación</v-card-title
          >

          <v-card-text class="textfield__text ma-0 py-2">
            {{
              assignedEvaluation.observation
                ? assignedEvaluation.observation
                : 'No fue realizada ninguna obsevación en esta evaluación'
            }}</v-card-text
          >
        </v-card>
      </v-row>

      <v-row class="ma-4">
        <v-col cols="12">
          <v-row class="mx-0">
            <span class="mx-0 text-h5 text-center font-weight-bold" style="width: 100%"
              >Resumen</span
            >

            <v-col cols="12" sm="6" md="4"
              ><strong> Secciones evaluadas: </strong> {{ sections.length }}</v-col
            >

            <v-col cols="12" sm="6" md="4">
              <strong> Resultado alcanzado: </strong>
              {{ assignedEvaluation.result }}
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <strong> Resultado mínimo requerido: </strong>
              {{ assignedEvaluation.target_result }}
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="assignedEvaluation.evaluation">
              <strong> Resultado alcanzable: </strong>
              {{ assignedEvaluation.evaluation.total_score }}
            </v-col>

            <v-col cols="12" sm="6" md="4"
              ><strong> Resultado porcentual: </strong>
              {{ assignedEvaluation.percent_result }}%
            </v-col>

            <v-col cols="12" sm="6" md="4"
              ><strong> Resultado cualitativo: </strong>
              {{ assignedEvaluation.qualitative_result | toQualitativeText }}
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          style="min-height: 500px"
          class="d-flex justify-center align-self-center chart-box avoid-pagebreak"
        >
          <apexchart
            class="avoid-pagebreak"
            :height="'auto'"
            :width="$vuetify.breakpoint.smAndDown ? '700' : '900'"
            :options="chartOptions"
            :series="series"
          />
        </v-col>
      </v-row>

      <v-card tag="section" class="" flat>
        <v-card-title class="avoid-pagebreak d-flex justify-center py-2"
          ><h3>Resultados generales</h3>
        </v-card-title>
        <v-data-table
          :headers="headers1"
          :items="sections"
          hide-default-footer
          sort-by=""
          :items-per-page="-1"
          class="mb-10 elevation-1 data-table"
        >
          <!-- <template v-slot:top>
        <v-divider class="my-2" />
        <v-row class="mx-0">
          <h3>{{ section.name }}</h3>
          <v-spacer />
          <h3>Resultado de la sección: {{ Math.round(section.result, 1) }}</h3>
        </v-row>
      </template> -->
          <template v-slot:[`header.percent_result`]="{ header }" class="avoid-pagebreak">
            <h3>
              <strong>{{ header.text }}</strong>
            </h3>
          </template>
          <template
            v-slot:[`header.qualitative_result`]="{ header }"
            class="avoid-pagebreak"
          >
            <h3>
              <strong>{{ header.text }}</strong>
            </h3>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td class="text-center">
                {{ item.result }}
              </td>
              <td class="text-center">
                {{ item.total_score }}
              </td>
              <td class="text-center">
                {{ item.weighing + '%' }}
              </td>
              <td class="text-center">
                {{ item.percent_result + '%' }}
              </td>
              <td class="text-center">
                {{ item.qualitative_result | toQualitativeText }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <!-- </v-card> -->

      <v-card tag="section" flat>
        <v-card-title class="avoid-pagebreak d-flex justify-center py-2"
          ><h3>Resultados por sección</h3>
        </v-card-title>

        <v-data-table
          v-for="(section, index) in sections"
          :key="index"
          :headers="headers2"
          :items="section.main_requirements"
          hide-default-footer
          sort-by=""
          :items-per-page="-1"
          class="mb-10 elevation-2 data-table"
        >
          <template v-slot:top>
            <v-divider class="my-2" />
            <v-row class="ma-2">
              <h3>{{ section.name }}</h3>
              <v-spacer />
              <h4>Resultado de la sección: {{ section.result }}</h4>
            </v-row>
          </template>
          <template v-slot:[`header.result`]="{ header }">
            <h3>
              <strong>{{ header.text }}</strong>
            </h3>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td class="pa-2">{{ item.description }}</td>
              <td class="text-center">
                {{
                  item.applied_evaluations.find(
                    (applied) =>
                      applied.assigned_evaluation_id === assignedEvaluation.id &&
                      applied.requirement_id === item.id
                  ) !== undefined
                    ? item.applied_evaluations.find(
                        (applied) =>
                          applied.assigned_evaluation_id === assignedEvaluation.id &&
                          applied.requirement_id === item.id
                      ).value
                    : 0
                }}
              </td>
              <td class="text-center">
                {{ item.required_value }}
              </td>
              <td class="text-center">
                {{ item.weighing + '%' }}
              </td>
              <td :class="`${getItemColor(item)}`" class="text-center">
                <h3>
                  {{
                    item.applied_evaluations.find(
                      (applied) =>
                        applied.assigned_evaluation_id === assignedEvaluation.id &&
                        applied.requirement_id === item.id
                    ) !== undefined
                      ? (item.applied_evaluations.find(
                          (applied) =>
                            applied.assigned_evaluation_id === assignedEvaluation.id &&
                            applied.requirement_id === item.id
                        ).value *
                          item.weighing) /
                        100
                      : 0
                  }}
                </h3>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-card tag="section" class="mx-8 my-4 disabled-textfield" flat>
        <v-card-title class="text-body-1 pb-0">Conclusión del evaluador</v-card-title>

        <v-card-text class="textfield__text ma-0 py-2">
          {{
            assignedEvaluation.conclusion
              ? assignedEvaluation.conclusion
              : 'No fue realizada ninguna conclusión para esta evaluación'
          }}</v-card-text
        >
      </v-card>
      <v-row tag="picture" justify="center">
        <img width="300" src="/static/default/logo-gris.svg" class="mt-8" />
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    data() {
      return {
        headers1: [
          {
            text: 'Sección Evaluada',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Resultado Obtenido',
            sortable: false,
            value: 'result',
            align: 'center',
          },
          {
            text: 'Resultado Objetivo',
            sortable: false,
            value: 'total_score',
            align: 'center',
          },
          {
            text: 'Peso Ponderado',
            sortable: false,
            value: 'weighing',
            align: 'center',
          },
          {
            text: 'Resultado porcentual',
            sortable: false,
            value: 'percent_result',
            align: 'center',
          },
          {
            text: 'Calificativo',
            sortable: false,
            value: 'qualitative_result',
            align: 'center',
          },
        ],
        headers2: [
          {
            text: 'Punto Evaluado',
            align: 'start',
            sortable: false,
            value: 'description',
          },
          {
            text: 'Puntuación Obtenida',
            sortable: false,
            value: 'required_value',
            align: 'center',
          },
          {
            text: 'Puntuación Objetivo',
            sortable: false,
            value: 'required_value',
            align: 'center',
          },
          {
            text: 'Peso Ponderado',
            sortable: false,
            value: 'weighing',
            align: 'center',
          },
          { text: 'Resultado', sortable: false, value: 'result', align: 'center' },
        ],
      }
    },
    filters: {
      toQualitativeText(text) {
        switch (text) {
          case 'Very deficient':
            return 'Muy deficiente'
          case 'Deficient':
            return 'Deficiente'
          case 'Good':
            return 'Bueno'
          case 'Very good':
            return 'Muy bueno'
          case 'Excellent':
            return 'Excelente'
          default:
            return ''
        }
      },
    },
    props: {
      assignedEvaluation: {
        type: Object,
        default: () => {
          return {}
        },
      },
      sections: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    computed: {
      series() {
        const serie1 = { name: 'Resultado obtenido', data: [] }
        const serie2 = { name: 'Resultado objetivo', data: [] }

        this.sections.forEach((section) => {
          serie1.data.push(section.result)
          serie2.data.push(section.total_score)
        })

        return [serie1, serie2]
      },
      chartOptions() {
        return {
          chart: {
            height: 350,
            type: 'radar',
            dropShadow: {
              enabled: true,
              blur: 1,
              left: 1,
              top: 1,
            },
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: [],
              },
              export: {
                csv: {
                  filename: undefined,
                  columnDelimiter: ',',
                  headerCategory: 'category',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString()
                  },
                },
                svg: {
                  filename: undefined,
                },
                png: {
                  filename: undefined,
                },
              },
              autoSelected: 'zoom',
              fontFamily: 'inherit',
            },
          },
          title: {
            text: '',
          },
          fill: {
            opacity: 0.1,
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: this.sections.map((section) => {
              return section.name
            }),
          },
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      getItemColor(item) {
        const result = item.applied_evaluations.find(
          (applied) =>
            applied.assigned_evaluation_id === this.assignedEvaluation.id &&
            applied.requirement_id === item.id
        )
        if (result !== undefined && result.value >= item.required_value) {
          return 'successColor'
        } else {
          return 'failedColor'
        }
      },
    },
  }
</script>
<style lang="scss">
  // .assigned-evaluation-report:not([data-scroll='0']) {
  //   padding-top: 3em;
  // }
  .assigned-evaluation-report .report-fixed-bar {
    position: fixed;
    top: 10%;
    z-index: 5;
    width: 90%;
  }
  .assigned-evaluation-report {
    .v-toolbar__content.v-toolbar__content.v-toolbar__content {
      padding: 0;
    }

    .v-image__image {
      background-size: inherit;
    }
    .successColor {
      border-style: solid;
      border-width: 8px;
      border-color: transparent rgba(67, 160, 71, 1) transparent transparent;
    }
    .failedColor {
      border-style: solid;
      border-width: 8px;
      border-color: transparent rgba(229, 57, 53, 1) transparent transparent;
    }
    .chart-box {
      overflow: auto;
    }
    .disabled-textfield {
      .textfield__text {
        border: solid 1px var(--v-primary-base);
        border-radius: 4px;
      }
    }
  }
</style>
